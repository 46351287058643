<!--财务统计-累计应收账款-->
<template>
    <div class="cumulativeReceivable">
        <div class="content-box">
            <div class="total-content">
                <ul class="total-list">
                    <li>
                        <div class="box">
                            <strong>{{ info.loans_receivable }}</strong>
                            <p><span class="iconfont iconyingshoukuan"></span>总累计应收(万元)</p>
                            <i class="icon iconfont iconyingshoukuan"></i>
                        </div>
                    </li>
                    <li>
                        <div class="box">
                            <strong>{{ info.official_receipt }}</strong>
                            <p><span class="iconfont iconyue"></span>总累计实收(万元)</p>
                            <i class="icon iconfont iconyue"></i>
                        </div>
                    </li>
                    <li>
                        <div class="box">
                            <strong>{{ info.ending_balance_receivable }}</strong>
                            <p><span class="iconfont iconfeiyong"></span>总应收账款余额(万元)</p>
                            <i class="icon iconfont iconfeiyong"></i>
                        </div>
                    </li>
                    <li>
                        <div class="box">
                            <strong>{{ info.overdue_arrears }}</strong>
                            <p><span class="iconfont iconhetong"></span>总逾期账款(万元)</p>
                            <i class="icon iconfont iconhetong"></i>
                        </div>
                    </li>
                    <li>
                        <div class="box">
                            <strong>{{ info.balance_receivable_rate }}</strong>
                            <p><span class="iconfont iconwanchengshuai"></span>总回款率(%)</p>
                            <i class="icon iconfont iconwanchengshuai"></i>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="table-list">
                <el-table
                    border
                    stripe
                    :data="listData"
                    ref="multipleTable"
                    :highlight-current-row="true"
                    v-loading="loading"
                    height="2.1rem"
                >
                    <el-table-column
                        :label="getName()"
                        prop="show_name"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        label="总累计应收(元)"
                        prop="loans_receivable"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        label="总累计实收(元)"
                        prop="official_receipt"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        label="总应收账款余额(元)"
                        prop="ending_balance_receivable"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        label="总逾期账款(元)"
                        prop="overdue_arrears"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        label="总回款率"
                        prop="balance_receivable_rate"
                        align="center"
                    >
                    </el-table-column>
                </el-table>
            </div>
            <div class="year-content">
                <div class="btn-group">
                    <div
                        v-for="item in types"
                        :key="item.val"
                        :class="{'btn-item':true,active:item.val===curType}"
                        @click="selectType(item.val)"
                    >
                        {{ item.name }}
                    </div>
                </div>
                <div class="charts-content">
                    <div id="balance-chart" class="chart-box"></div>
                    <div id="recovery-chart" class="chart-box"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cumulative-receivable',
    data() {
        return {
            dateRange: [],
            tableList: [],
            loading: false,

            info: {}, // 数据信息
            listData: [], // 列表数据

            types: [{
                name: '按销售公司',
                val: 1,
            }, {
                name: '按合同类型',
                val: 2,
            }],
            curType: 1,
        };
    },
    mounted() {
        this.selectType(1);
    },
    methods: {
        // 选择类型
        selectType(type) {
            this.curType = type;
            this.getData();

        },
        getName() {
            let name = '';
            for (const obj of this.types) {
                if (obj.val === this.curType) {
                    name = obj.name;
                    break;
                }
            }
            return name;
        },
        // 获取全部合同应收
        // 获取全部合同应收
        getData() {
            const path = '/interfaceApi/sale/accounts/receivable_total_group?type=' + this.curType;
            return this.$axios.get(path).then(res => {
                if (res) {
                    this.info.loans_receivable = (res.loans_receivable / 10000).toFixed(2);
                    this.info.official_receipt = (res.official_receipt / 10000).toFixed(2);
                    this.info.ending_balance_receivable = (res.ending_balance_receivable / 10000).toFixed(2);
                    this.info.overdue_arrears = (res.overdue_arrears / 10000).toFixed(2);
                    this.info.balance_receivable_rate = res.balance_receivable_rate + '%';
                    this.listData = res.receivableList;

                    this.lineColumn('balance-chart');
                    this.pieChart('recovery-chart');
                }
            });
        },

        lineColumn(obj) {
            const data = {
                xAxis: [],
                list1: [], // 年度累计应收
                list2: [], // 年度累计实收
                list3: [], // 本年货款回收率
            };

            this.listData.forEach(item => {
                data.xAxis.push(item.show_name);
                data.list1.push((item.loans_receivable / 10000).toFixed(2));
                data.list2.push((item.official_receipt / 10000).toFixed(2));
                data.list3.push(item.balance_receivable_rate);
            });
            const lineColumnChart = window.$globalHub.$echarts.init(document.getElementById(obj));
            const option = {
                title: {
                    text: '货款回收率',
                    left: 'left',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            show: true,
                        },
                    },
                },
                legend: {
                    textStyle: {
                        color: '#666666',
                    },
                    top: 0,
                    itemGap: 50,
                },
                grid: {
                    left: 60,
                    top: 70,
                    right: 60,
                    bottom: 40,
                },
                xAxis: [{
                    // type:'category',
                    data: data.xAxis,
                    boundaryGap: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(133,133,133,0.2)',
                        },
                    },
                    splitArea: {
                        color: '#f00',
                        lineStyle: {
                            color: '#f00',
                        },
                    },
                    axisLabel: {
                        color: '#979EA7',
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                yAxis: [
                    {
                        name: '(万元)',
                        nameLocation: 'end',
                        nameTextStyle: {
                            color: '#979EA7',
                            padding: '',
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.1)',
                                width: 0.5,
                                type: 'dashed',
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(133,133,133,0.2)',
                            },
                        },
                        axisLabel: {
                            show: true,
                            color: '#979EA7',
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    {
                        name: '(%)',
                        nameLocation: 'end',
                        nameTextStyle: {
                            color: '#979EA7',
                            padding: '',
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(133,133,133,0.2)',
                                width: 0.5,
                                type: 'solid',
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(133,133,133,0.2)',
                            },
                        },
                        axisLabel: {
                            show: true,
                            color: '#979EA7',
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '总累计应收',
                        barWidth: 30,
                        type: 'bar',
                        itemStyle: {
                            normal: {
                                color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#55A7FE',
                                },
                                {
                                    offset: 1,
                                    color: '#3E79C5',
                                },
                                ]),
                            },
                        },
                        data: data.list1,
                    },
                    {
                        name: '总累计实收',
                        type: 'bar',
                        barWidth: 30,
                        yAxisIndex: 0,
                        stack: '总累计实收',
                        itemStyle: {
                            normal: {
                                color: new window.$globalHub.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#EFAB54',
                                },
                                {
                                    offset: 1,
                                    color: '#FA8C47',
                                },
                                ]),
                            },
                        },
                        data: data.list2,
                    },
                    {
                        name: '总回款率',
                        type: 'line',
                        // smooth: true,
                        showSymbol: true,
                        symbolSize: 10,
                        lineStyle: {
                            normal: {
                                color: '#21BC9A',
                                width: 2,
                            },
                        },
                        yAxisIndex: 1,
                        label: {
                            show: false,
                            position: 'top',
                            textStyle: {
                                color: '#FC9343',
                            },
                        },
                        itemStyle: {
                            color: '#6c50f3',
                            borderColor: '#fff',
                            borderWidth: 3,
                            shadowColor: 'rgba(0, 128, 100, 0.5)',
                            shadowBlur: 0,
                            shadowOffsetY: 2,
                            shadowOffsetX: 2,
                            normal: {
                                color: '#21BC9A',
                                lineStyle: {
                                    color: '#21BC9A',
                                },
                            },
                        },
                        data: data.list3,
                    },
                ],
            };
            lineColumnChart.setOption(option);
        },

        pieChart(obj) {
            const chart = window.$globalHub.$echarts.init(document.getElementById(obj));
            // 默认四种渐变颜色
            const colors = [
                ['#76E0A8', '#13BD63'],
                ['#71C6B0', '#4EA496'],
                ['#48A0D8', '#7FD0FE'],
                ['#F79187', '#EA5D5D']];


            const data = [];
            this.listData.forEach(item => {
                data.push({
                    name: item.show_name,
                    value: item.ending_balance_receivable,
                });
            });
            let total = 0;
            data.forEach(item => {
                total += item.value;
            });
            data.forEach(item => {
                item.name += '(' + (item.value / total * 100).toFixed(1) + '%)';
                item.value = (item.value / 10000).toFixed(2);
            });
            const result = [];
            data.forEach((item, index) => {
                const obj = {
                    value: item.value,
                    name: item.name,
                    label: {
                        alignTo: 'edge',
                        formatter: '{name|{b}} \n{value|{c}万}',
                        minMargin: 5,
                        edgeDistance: 10,
                        lineHeight: 15,
                        rich: {
                            name: {
                                color: '#666',
                            },
                            value: {
                                fontSize: 10,
                                color: '#666',
                            },
                        },
                        labelLine: {
                            length: 15,
                            length2: 0,
                            maxSurfaceAngle: 0,
                        },
                    },
                };
                if (index < colors.length) {
                    obj.itemStyle = {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: colors[index][0], // 0% 处的颜色
                            }, {
                                offset: 1, color: colors[index][1], // 100% 处的颜色
                            }],
                            global: false, // 缺省为 false
                        },
                    };
                }
                result.push(obj);
            });
            const option = {
                title: {
                    text: '总应收账款余额',
                    left: 'left',
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        center: ['50%', '50%'],
                        selectedMode: 'single',
                        data: result,
                        labelLine: {
                            normal: {
                                length: 30,
                                length2: 300,
                            },
                        },
                        emphasis: {
                            show: false,
                        },
                    },
                ],
            };
            chart.setOption(option);
        },
    },
};
</script>

<style lang="stylus" scoped>
    .cumulativeReceivable{
        width 100%;
        height 100%;
        padding 0.1rem;
        .content-box{
            width 100%;
            height 100%;
            background #fff ;
            padding 0.13rem;
            .titlec{
                font-size 0.16rem;
                font-weight 500
                color #333;
            }
            .total-content{
                .total-list{
                    padding 0.1rem 0;
                    display flex
                    justify-content space-between;
                    flex-wrap wrap;
                    li{
                        box-sizing border-box
                        padding-left 0.3rem
                        width: 3.12rem;
                        height: 1.2rem;
                        border-radius 0.05rem;
                        &:nth-child(1){
                            background: linear-gradient(270deg, #55A7FE 0%, #3E79C5 100%);
                        }
                        &:nth-child(2){
                            background: linear-gradient(270deg, #EFAB54 0%, #FA8C47 100%)
                        }
                        &:nth-child(3){
                            background: linear-gradient(260deg, #71C6B0 0%, #4EA496 100%);
                        }
                        &:nth-child(4){
                            background: linear-gradient(270deg, #F79187 0%, #EA5D5D 100%);
                        }
                        &:nth-child(5){
                            background: linear-gradient(90deg, #576F94 0%, #7791B9 100%);
                        }
                        .box{
                            width 100%
                            height 100%
                            display flex
                            justify-content  center
                            flex-direction column
                            background-size contain
                            position relative
                            background  url("./images/bg5.png")
                            strong{
                                font-size 0.32rem;
                                color #fff;
                                position relative
                                z-index 3
                            }
                            p{
                                margin-top 0.1rem;
                                font-size 0.16rem;
                                color #fff;
                                .iconfont{
                                    font-size 0.16rem;
                                    color #fff;
                                    margin-right 0.03rem;
                                }
                            }
                            .icon{
                                position absolute;
                                right 0.3rem;
                                top 0.35rem;
                                font-size 0.5rem
                                color rgba(255,255,255,0.3);
                                z-index 2
                            }
                        }
                    }
                }
            }
            .year-content{
                height calc(100% - 1.9rem)
                .btn-group{
                    margin 0.18rem 0;
                    display flex
                    .btn-item{
                        width: 1.4rem;
                        height: 0.4rem;
                        border: 1px solid #D7D7D7;
                        border-radius: 2px;
                        display flex
                        justify-content center
                        align-items center
                        font-size 0.18rem;
                        color #666;
                        margin-right 0.25rem;
                        cursor pointer
                        &.active{
                            background: linear-gradient(270deg, #55A7FE 0%, #3E79C5 100%);
                            border 0;
                            color #fff;
                        }
                    }
                }
                .charts-content{
                    margin-top: 0.1rem;
                    height: calc(100% - 2.3rem);
                    display: flex;
                    justify-content space-between
                    .chart-box{
                        width 49.6%;
                        height 100%;
                    }
                }
            }
        }
    }
</style>
